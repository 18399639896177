
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      components: {Header, Footer},
      name: 'BusinessPage',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
    }
);
