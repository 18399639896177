import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "bodyWrap" }
const _hoisted_2 = { class: "businessSection" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "businessBlock" }
const _hoisted_5 = { class: "wrapper" }
const _hoisted_6 = { class: "titleBlock" }
const _hoisted_7 = {
  class: "title",
  style: {"font-size":"38px"}
}
const _hoisted_8 = { class: "subtitle" }
const _hoisted_9 = { class: "contentBlock" }
const _hoisted_10 = { class: "listTempl1" }
const _hoisted_11 = { class: "num" }
const _hoisted_12 = { class: "num" }
const _hoisted_13 = { class: "num" }
const _hoisted_14 = { class: "num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("div", _hoisted_6, [
              _createVNode("span", _hoisted_7, _toDisplayString(_ctx.t("businessSection.subtitle")), 1),
              _createVNode("span", _hoisted_8, _toDisplayString(_ctx.t("businessSection.title")), 1)
            ]),
            _createVNode("div", _hoisted_9, [
              _createVNode("ul", _hoisted_10, [
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_11, _toDisplayString(_ctx.t("businessSection.contentBlock[0].id")), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("businessSection.contentBlock[0].value")), 1)
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_12, _toDisplayString(_ctx.t("businessSection.contentBlock[1].id")), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("businessSection.contentBlock[1].value")), 1)
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_13, _toDisplayString(_ctx.t("businessSection.contentBlock[2].id")), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("businessSection.contentBlock[2].value")), 1)
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_14, _toDisplayString(_ctx.t("businessSection.contentBlock[3].id")), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("businessSection.contentBlock[3].value")), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}